export default {
    1: "STANDARD_CAR",
    2: "MOTO",
    3: "BICYCLE",
    4: "VAN",
    5: "TRICYCLE",
    6: "AMBULANCE",
    7: "TRUCK",
    8: "LUXURY_CAR",
    9: "PICKUP_TRUCK",
    10: "TRAILER"
}