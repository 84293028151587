import { apiAddress } from '../variables/secretEnvVariables';
import axios from 'axios';
import { logout } from "../actions/index";
import { store } from "../store/index";

export const API_VERSION_1 = "api/v1/ana/";
export const API_VERSION_2 = "api/v2/";
export const API_VERSION_3 = "api/v3/";
export const API_VERSION_4 = "api/v4/";

const encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

let storeState = store.getState();
store.subscribe(() => {
    storeState = store.getState();
});

export function sendLoggedGET(endpoint, apiVers = API_VERSION_1, ignoreNotFound = false, options = {}, filter_zone_id = null) {
    const url = apiAddress + apiVers + endpoint;
    // console.log('storeState.userData.authToken', storeState.userData.authToken);
    const zone_id = filter_zone_id || storeState.zones.selected.zone_id;
    const params = encodeGetParams({ zone_id });
    return axios.get(url, {
        params: { zone_id },
        headers: {
            'Authorization': storeState.userData.authToken
        },
        ...options
    }).then(result => {
        return result;
    }).catch(error => {
        console.log('Error network probably not taken care of new Arch');
        console.log('error', error);
        /*if (error.response.status === 401) {

        } else if (error.response.status === 404) {
            if (!ignoreNotFound) {
                window.location.href = "/#/not_found";
            }
            return;
        } else {
            throw error;
        }*/
    });
}

export function sendLoggedGETResponse(endpoint, apiVers = API_VERSION_1, ignoreNotFound = false, additional_options = null) {
    const url = apiAddress + apiVers + endpoint;
    return axios.get(url, {
        params: {},
        headers: {
            'Authorization': storeState.userData.authToken
        },
        ...additional_options
    })
}


export function sendLoggedDELETE(endpoint, apiVers = API_VERSION_1, ignoreNotFound = false) {
    const url = apiAddress + apiVers + endpoint;
    return axios.delete(url, {
        params: {},
        headers: {
            'Authorization': storeState.userData.authToken
        }
    }).then(result => {
        return result;
    }).catch(error => {
        if (error.response.status === 401) {

        } else if (error.response.status === 404) {
            if (!ignoreNotFound) {
                window.location.href = "/#/not_found";
            }
            return;
        } else {
            throw error;
        }
    });
}

export function sendLoggedPOST(endpoint, body, apiVers = API_VERSION_1) {
    const url = apiAddress + apiVers + endpoint;
    const zone_id = storeState.zones.selected.zone_id;
    // console.log('storeState.userData.authToken', storeState.userData.authToken);
    // console.log('zone_id', zone_id);
    return axios.post(url, body, {
        params: { zone_id },
        headers: {
            'Authorization': storeState.userData.authToken
        }
    }).then(result => {
        return result;
    }).catch(error => {
        console.log('error', error);
        if (error.response.status === 401) {
            store.dispatch(logout());
        } else if (error.response.status === 404) {
            window.location.href = "/not_found";
            return;
        } else {
            throw error;
        }
    });
}

export async function sendLoggedPOSTAsync(endpoint, body, apiVers = API_VERSION_1, ignoreNotFound = false) {
    const url = apiAddress + apiVers + endpoint;
    const zone_id = storeState.zones.selected.zone_id;
    const response = await axios.post(url, body, {
        params: { zone_id },
        headers: {
            'Authorization': storeState.userData.authToken
        }
    }).then(result => {
        return result;
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch(logout());
        } else if (error.response.status === 404) {
            if (!ignoreNotFound) {
                window.location.href = "/not_found";
                return;
            }
            return error;
        } else if (error.response.status === 400) {
            return error.response;
        } else {
            throw error;
        }
    });
    return response;
}

export async function sendLoggedPUTAsync(endpoint, body, apiVers = API_VERSION_1) {
    const url = apiAddress + apiVers + endpoint;
    const response = await axios.put(url, body, {
        params: {},
        headers: {
            'Authorization': storeState.userData.authToken
        }
    }).then(result => {
        return result;
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch(logout());
        } else if (error.response.status === 404) {
            window.location.href = "/not_found";
            return;
        } else {
            throw error;
        }
    });
    return response;
}


/*export function signin(email, password) {
    const endpoint = apiAddress + "signin";
    let success = false;
    let token = null;
    let communityAccount = null;
    let firstName = null;
    let lastName = null;
    let userId = null;
    return axios.post(endpoint, { email, password }).then(response => {
        success = response.data.success;
        token = response.data.token;
        communityAccount = response.data.community_account;
        firstName = response.data.first_name;
        lastName = response.data.last_name;
        userId = response.data.user_id;
        return { success, token, communityAccount, firstName, lastName, userId };
    }).catch(error => {
        return { success, token, communityAccount };
    });
}*/
const API_ADDRESS_V3 = process.env.REACT_APP_API_ADDRESS_V3;
const CAPTCHA_TOKEN = process.env.REACT_APP_CAPCHA_TOKEN;

export function signin(email, phone) {
    const endpoint = `${API_ADDRESS_V3}authentication/`;
    phone = "+" + phone
    return axios.post(endpoint, { email, phone }, {
        headers: {
            'accept': 'application/json',
            'CaptchaToken': CAPTCHA_TOKEN,
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            // Assuming the API response will include some kind of session/token information for the next step
            // console.log('Sign In Data', response.data);
            return {
                success: response.data.status
            };
        })
        .catch(error => {
            console.error('Signin Error:', error);
            return { success: false };
        });
}

export const authenticateUser = async (phone, token) => {
    const endpoint = `${API_ADDRESS_V3}authentication/`;
    try {
        const response = await axios.put(endpoint, {
            phone: phone,
            token: token
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

/*export function auth() {
    return sendLoggedGET("auth").then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}*/
export function auth() {
    const endpoint = `${API_ADDRESS_V3}authentication/`;
    const phone = storeState.userData.phone
    // console.log('User data');
    // console.log(storeState.userData);
    return axios.post(endpoint, { phone }, {
        headers: {
            'accept': 'application/json',
            'CaptchaToken': CAPTCHA_TOKEN,
            'Content-Type': 'application/json',
            'Authorization': storeState.userData.authToken
        },
    })
        .then(response => {
            // Assuming the API response will include some kind of session/token information for the next step
            // console.log('Sign In Data', response.data);
            return {
                success: response.data.status
            };
        })
        .catch(error => {
            console.error('Signin Error:', error);
            return { success: false };
        });
}


export function getTokenTransfers() {
    return sendLoggedGET("get_token_transfers").then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getCommunity() {
    return sendLoggedGET("get_community").then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getTokenTransfersOfAccount(account_name) {
    return sendLoggedGET("get_token_transfers_by_account/" + account_name).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getCompletedRides(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET("get_completed_rides?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getBalance(phonenumber) {
    return sendLoggedGET("get_balance/" + phonenumber).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}



export function getCompletedRide(route_id) {
    const params = encodeGetParams({ route_id });
    return sendLoggedGET("get_completed_ride?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getRider(account) {
    const params = encodeGetParams({ account });
    return sendLoggedGET("get_rider?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error
    });
}

export function getRiderNotDriverByPhone(phone) {
    const params = encodeGetParams({ phone });
    return sendLoggedGET("get_rider_not_driver_by_phone?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error("API ERROR", error);
        throw error
    });
}

export function getRideRequestHistorical(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET(
        "get_ride_requests?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getRideRequestHistoricalById(ride_request_id) {
    const params = encodeGetParams({ ride_request_id });
    return sendLoggedGET("get_ride_request_by_id?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getDriverRidePositions(ride_request_id) {
    const params = encodeGetParams({ ride_request_id });
    return sendLoggedGET("get_driver_positions?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.log('Error retrieving driver ride positions');
        console.error(error);
        return error;
    });
}

export function getActiveRides(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET("get_driver_active_rides?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getDriverScheduledRoutes() {
    return sendLoggedGET("get_driver_scheduled_routes").then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getUserZones() {
    return sendLoggedGET(`zones/zoneuser`, API_VERSION_3).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getScheduledRides(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET("get_scheduled_rides?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getCanceledDriverRequest(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET("get_canceled_driver_request?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getArchivedScheduledRides(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET("get_archived_scheduled_rides?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getArchivedScheduledRide(ride_request_id, ignoreNotFound = false) {
    const params = encodeGetParams({ ride_request_id });
    return sendLoggedGET("get_archived_scheduled_ride?" + params, API_VERSION_1, ignoreNotFound).then(result => {
        return result && result.data || null;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getDrivers(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET(
        "get_drivers?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getDeactivatedDrivers(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET(
        "get_deactivated_drivers?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getDriver(account, ignoreNotFound = false) {
    const params = encodeGetParams({ account });
    return sendLoggedGET("get_driver?" + params, API_VERSION_1, ignoreNotFound).then(result => {
        return result.data;
    }).catch(error => {
        return error;
    });
}

export function getDriverOnlineSessions(account_name) {
    const params = encodeGetParams({
        account_name
    });
    return sendLoggedGET("get_driver_online_sessions?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getOnlineDrivers() {
    return sendLoggedGET("get_online_drivers").then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getBlacklistedRiders(data) {
    return getRiders(data, true);
}

export function getRiders(data, is_blacklisted = false) {
    const params = encodeGetParams({
        is_blacklisted,
        ...data
    });
    return sendLoggedGET(
        "get_riders?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function deleteDriver(account_name) {
    return sendLoggedPOST("blacklist_driver", { account_name }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function undeleteDriver(account_name) {
    return sendLoggedPOST("unblacklist_driver", { account_name }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function switchDriverFlag(driver) {
    return sendLoggedPOST("switch_driver_flag", { driver }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function activateDriver(account_name) {
    return sendLoggedPOST("activate_driver", { account_name }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}


export function editDriver(account_name, profile, driver_vehicles) {
    return sendLoggedPOST("edit_driver", { account_name, profile, driver_vehicles }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function newDriver(account_name, profile, driver_vehicles) {
    return sendLoggedPOST("new_driver", { account_name, profile, driver_vehicles }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function cancelActiveRide(route_id) {
    return await sendLoggedPOSTAsync("cancel_active_ride", { route_id }).then(function (response) {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function cancelCommunityCustomerRide(ride_request_id) {
    return await sendLoggedPOSTAsync("cancel_community_ride", { ride_request_id }).then(function (response) {
        return response;
    })
        .catch(error => {
            console.log('cancelCommunityCustomerRide');
            console.error(error);
            return error;
        });
}


export async function demergeRide(ride_id) {
    return await sendLoggedPOSTAsync("demerge_ride", { ride_id }).then(function (response) {
        return response;
    })
        .catch(error => {
            console.log('demergeRide');
            console.error(error);
            return error;
        });
}

export async function selectStep(route_id, step_id) {
    return await sendLoggedPOSTAsync(`routes/${route_id}/step/${step_id}/select`, {}, API_VERSION_4).then(function (response) {
        return response;
    })
        .catch(error => {
            console.log('selectStep');
            console.error(error);
            return error;
        });
}

export async function resetRoute(route_id) {
    return await sendLoggedPOSTAsync(`routes/${route_id}/reset`, {}, API_VERSION_4)
}

export async function completeStep(route_id, step_id) {
    return await sendLoggedPOSTAsync(`routes/${route_id}/step/${step_id}/complete`, {}, API_VERSION_4).then(function (response) {
        return response;
    })
        .catch(error => {
            console.log('completeStep');
            console.error(error);
            return error;
        });
}

export async function resetStep(route_id, step_id) {
    return await sendLoggedPOSTAsync(`routes/${route_id}/step/${step_id}/reset`, {}, API_VERSION_4).then(function (response) {
        return response;
    })
        .catch(error => {
            console.log('resetStep');
            console.error(error);
            return error;
        });
}

export async function endActiveRide(route_uuid) {
    return await sendLoggedPOSTAsync("end_active_ride", { route_uuid }).then(function (response) {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function startActiveRide(rider_ride_uuids, dactiveride_and_driver_request_uuid) {
    return await sendLoggedPOSTAsync("start_active_ride", { rider_ride_uuids, dactiveride_and_driver_request_uuid }).then(function (response) {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function redispatchActiveRide(dactiveride_and_driver_request_uuid) {
    return await sendLoggedPOSTAsync("redispatch_active_ride", { dactiveride_and_driver_request_uuid }).then(function (response) {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function incrementStepActiveRide(dactiveride_and_driver_request_uuid) {
    return await sendLoggedPOSTAsync("increment_step_ride", { dactiveride_and_driver_request_uuid }).then(function (response) {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function dispatchScheduledRide(tracking_id) {
    return await sendLoggedPOSTAsync("dispatch_scheduled_ride", { tracking_id }).then(function (response) {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function editScheduledRide(tracking_id, data) {
    return await sendLoggedPUTAsync(`rides/${tracking_id}`, data, API_VERSION_2).then(function (response) {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function manualDispatchRide(driver_route_uuid, driver_phone) {
    const content = {
        driver_route_uuid,
        driver_phone
    }
    return await sendLoggedPOSTAsync(`manual_assign`, content,).then(function (response) {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function getQuote(fromLat, fromLon, toLat, toLon, rideServiceId) {
    const body = {
        to_latitude: toLat,
        to_longitude: toLon,
        from_latitude: fromLat,
        from_longitude: fromLon,
        ride_service_id: rideServiceId,
    };
    return await sendLoggedPOST("get_quote_ana", body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export function sendDiscount(rider) {
    return sendLoggedPOST("send_discount", { rider }).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function generateInvoice(to_email, route_id) {
    const endpoint = apiAddress + "generate_invoice";
    return axios.post(endpoint, { route_id, to_email })
        .then(function (response) {
            return response;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function sendTokens(to_account, amount_token, memo, message) {
    return sendLoggedPOST("transfer_tokens", { to_account, amount_token, memo, message }).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function newDebt(from_account, amount_token, memo) {
    return sendLoggedPOST("new_debt", { from_account, amount_token, memo }).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getDashboardData() {
    return sendLoggedGET("get_dashboard_data").then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

/**
 * Get pending payments for requested by drivers
 */
export function getPendingPayments() {
    return sendLoggedGET("get_pending_payments").then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}


/**
 * Get invoices
 */
export function getInvoices(pending = true) {
    const zone_id = storeState.zones.selected.zone_id;
    return sendLoggedGET("invoices", API_VERSION_1, false, {}, zone_id).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

/**
 * THis function makes an api call to retrieve a table from the blockchain
 */
export function getPaySequence() {
    return sendLoggedGET("get_paysequence").then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    })
}

/**
 * This function makes an api call to retrieve compcashouts from the blockchain
 */

export async function getCompletedPayments(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET("get_compcashouts?" + params).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    })
}

export async function getCompletedPaymentsTotal(filterObj) {
    const params = encodeGetParams(filterObj);
    return sendLoggedGET("get_compcashouts_total?" + params).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    })
}


export async function getDriverCashCollections(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET("get_driver_cash_collections?" + params).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    })
}

export async function getRideServicesByType() {
    return await sendLoggedGET("get_ride_services_by_type_ana")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export function getRideServiceInfo() {
    return sendLoggedGET("get_ride_service_info").then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getRideOpportunities(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET(
        "get_ride_opportunities?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function getReportsList(data) {
    return sendLoggedGET(
        "get_reports_list"
    ).then(result => {
        return result.data.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

export function sendCommunicationTask(accountList, message, communication_type) {
    return sendLoggedPOST("send_communication_task", { account_list: accountList, message, communication_type }).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function generateReport(reportTypeKey, startDate, endDate) {
    let params_dict = {
        "report_type": reportTypeKey,
    };
    if (startDate) {
        params_dict['start_time'] = startDate;
    }
    if (endDate) {
        params_dict['end_time'] = endDate;
    }
    const params = encodeGetParams(params_dict);
    const url = apiAddress + "generate_report?" + params;
    window.open(url);
}

export async function getJTFDriversList() {
    return sendLoggedGET(
        "get_jtf_drivers_list"
    ).then(result => {
        console.log('data', result.data);
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function getJtfDriverShifts(jtf_account) {
    return sendLoggedGET(
        "get_jtf_driver_shifts?jtf_account=" + jtf_account
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function removeJtfDriver(account_name) {
    return await sendLoggedPOSTAsync("remove_jtf_driver", { account_name }).then(function (response) {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function addJtfShift(account_name, start_time, end_time) {
    return sendLoggedPOSTAsync("add_jtf_shift", { account_name, start_time, end_time }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function addJtfDriver(account_name, hourly_rate) {
    return sendLoggedPOSTAsync("add_jtf_driver", { account_name, hourly_rate }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function editJtfDriverSalary(account_name, hourly_rate) {
    return sendLoggedPOSTAsync("edit_jtf_driver_salary", { account_name, hourly_rate }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export async function removeJtfShift(shift_id) {
    return sendLoggedPOSTAsync("remove_jtf_shift", { shift_id }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getJtfDriversCount() {
    return sendLoggedGET(
        "get_jtf_drivers_count"
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getJtfShiftDone(driver_account) {
    return sendLoggedGET(
        "get_jtf_driver_shift_done?jtf_account=" + driver_account
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    })
}

export function getJtfShiftDoneCount(driver_account) {
    return sendLoggedGET(
        "get_jtf_driver_shift_done_count?jtf_account=" + driver_account
    ).then(result => {
        console.log("d", result.data.lenght)
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    })
}


export function blacklistRider(rider) {
    return sendLoggedPOST("blacklist_rider", { rider }).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function deleteRider(rider) {
    return sendLoggedPOST("delete_rider", { rider }).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function whitelistRider(rider) {
    return sendLoggedPOST("whitelist_rider", { rider }).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function addSequence(data) {
    return sendLoggedPOST("add_paysequence", { data })
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function generateDeposit(drivers, sequence_number) {
    return sendLoggedPOST("generate_deposit_file", { drivers, sequence_number })
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function issuePayment(driver, sequence_id) {
    return sendLoggedPOST("issue_payment", { driver, sequence_id })
        .then(function (response) {
            return response;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function issueDriverCashPaymentCollection(cashCollectionEntry) {
    return sendLoggedPOST("issue_driver_cash_payment_collection", { "driver": cashCollectionEntry.driver })
        .then(function (response) {
            return response;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function getJtfShifts() {
    const params = encodeGetParams({
    });
    return sendLoggedGET(
        "get_jtf_shifts?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    })
}

export function getDriverSubmissions(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET(
        "get_driver_submissions?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getNewDriverSubmission(phone, ignoreNotFound = false) {
    const params = encodeGetParams({ phone });
    const apiVers = API_VERSION_2
    const zone_id = storeState.zones.selected.zone_id;
    const endpoint = `drivers/submissionsadmin/${phone}`;
    const url = apiAddress + apiVers + endpoint;
    console.log('storeState.userData.authToken', storeState.userData.authToken);
    return axios.get(url, {
        params: {
            zone_id
        },
        headers: {
            'Authorization': storeState.userData.authToken
        }
    }).then(result => {
        return result.data;
    }).catch(error => {
        console.log('Error network probably not taken care of new Arch');
        console.log('error', error);
    });
}

export function saveDriverSubmission(payload, isExistingSubmission = true) {
    const phone = payload['phone'];
    const zone_id = storeState.zones.selected.zone_id;
    const sendLoggedAction = isExistingSubmission ?
        sendLoggedPUTAsync(`drivers/submissionsadmin/${phone}?zone_id=` + zone_id, payload, API_VERSION_2) :
        sendLoggedPOST(`drivers/submissions?zone_id=` + zone_id, payload, API_VERSION_2);
    return sendLoggedAction.then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

export function uploadDriverSubmissionFiles(phone, formData) {
    return sendLoggedPOST(`drivers/submissionsadmin/${phone}/files`, formData, API_VERSION_2).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

export async function getNewDriverSubmissionFile(phone, fileName) {
    const url = `${apiAddress + API_VERSION_2}drivers/submissionsadmin/${phone}/files/${fileName}?zone_id=${storeState.zones.selected.zone_id}`;
    return await axios.get(url, {
        params: {},
        headers: {
            'Authorization': storeState.userData.authToken
        },
        responseType: 'blob'
    }).then(result => {
        return result;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

export async function migrateSubmission(payload, params) {
    console.log('payload', payload);
    console.log('migration params', params);
    const zone_id = storeState.zones.selected.zone_id;
    /*const phone = payload['phone'];
    const zone_id = storeState.zones.selected.zone_id;
    const sendLoggedAction = isExistingSubmission ?
        sendLoggedPUTAsync(`drivers/${phone}?zone_id=` + zone_id, payload, API_VERSION_2) :
        sendLoggedPOST(`drivers/submissions`, payload, API_VERSION_2);
    return sendLoggedAction.then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });*/
    const url = apiAddress + API_VERSION_2 + `drivers/${payload['phone']}?zone_id=` + zone_id;
    const response = await axios.put(url, payload, {
        headers: {
            'Authorization': storeState.userData.authToken
        }
    }).then(result => {
        return result;
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch(logout());
        } else if (error.response.status === 404) {
            window.location.href = "/not_found";
            return;
        } else {
            throw error;
        }
    });
    return response;
}

export async function getNewDriverSubmissions(data) {
    data['zone_id'] = storeState.zones.selected.zone_id;
    const params = encodeGetParams(data);
    const url = apiAddress + API_VERSION_1 + "get_new_driver_submissions?" + params;
    return await axios.get(url, {
        headers: {
            'Authorization': storeState.userData.authToken
        },
    }).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

export function getDriverSubmissionsArchive(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET(
        "get_driver_submissions_archive?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function sendSubmissionEmailReminder(phone) {
    // Subject to change once we figure out the final data structure of submissions and decide of a more official format for the email
    return sendLoggedPOST("send_submission_email_reminder", { phone }).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

export async function getDriverFormFile(filename) {
    const url = apiAddress + "get_file/" + filename;
    const response = await axios.get(url, {
        params: {},
        headers: {
            'Authorization': storeState.userData.authToken
        },
        responseType: 'blob'
    }).then(result => {
        return window.URL.createObjectURL(new Blob([result.data]));
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch(logout());
        } else {
            throw error;
        }
    });
    return response;
}

export function getDriverSubmission(phone) {
    const params = encodeGetParams({ phone });
    return sendLoggedGET("get_driver_submission?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getDriverFormTemplate(target) {
    const params = encodeGetParams({ target });
    return sendLoggedGET("get_driver_form_template?" + params).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error
    });
}

export async function getDriverSubmissionFile(data) {
    const url = apiAddress + "get_driver_submission_file";
    const response = await axios.get(url, {
        params: {
            filename: data.filename,
            identifier: data.identifier,
            phone: data.phone,
        },
        headers: {
            'Authorization': storeState.userData.authToken
        },
        responseType: 'blob'
    }).then(result => {
        return window.URL.createObjectURL(new Blob([result.data]));
    }).catch(error => {
        if (error.response.status === 401) {
            store.dispatch(logout());
        } else {
            throw error;
        }
    });
    return response;
}

export function getDebugOptions(code) {
    return sendLoggedPOST("get_debug_options", { code })
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}


export function archiveDriverSubmission(submission_id) {
    return sendLoggedPOST("archive_driver_submission", { submission_id })
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function archiveNewDriverSubmission(phone) {
    return sendLoggedPOST("archive_new_driver_submission", { phone })
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function setDebugOptions(entries) {
    return sendLoggedPOST("set_debug_options", { entries })
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}


export function restoreDriverSubmission(submission_id) {
    return sendLoggedPOST("restore_driver_submission", { submission_id })
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function registerBusiness(email, password, company_name, company_phone, contact_first_name, contact_last_name, contact_phone, contact_email, preferred_language, send_sms_tracking, send_email_tracking, default_address, apt_number, parent_rider_account, billing_email) {
    return sendLoggedPOST("register_business", {
        email, password, company_name, company_phone, contact_first_name, contact_last_name, contact_phone, contact_email, preferred_language, send_sms_tracking, send_email_tracking, default_address, apt_number, parent_rider_account, billing_email
    })
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function addRestaurant(data) {
    return sendLoggedPOST("create_restaurant", data).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function updateRestaurant(data) {
    return sendLoggedPOST("update_restaurant", data).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getRestaurants(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET(
        "get_restaurants_list?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getRestaurantsCategories() {
    return sendLoggedGET(
        "get_restaurants_categories"
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getCommunitySettings(code) {
    const endpoint = "get_community_settings";
    const url = apiAddress + API_VERSION_1 + endpoint;
    const zone_id = storeState.zones.selected.zone_id;
    // const params = encodeGetParams({ zone_id });
    return axios.get(url, {
        params: { zone_id, code },
        headers: {
            'Authorization': storeState.userData.authToken
        }
    }).then(result => {
        return result;
    }).catch(error => {
        console.log('Error network probably not taken care of new Arch');
        console.log('error', error);
    });
}

export function getRestaurant(id) {
    return sendLoggedGET(
        "get_restaurant_by_id?id=" + id
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function deleteRestaurant(id) {
    return sendLoggedPOST("delete_restaurant", { 'id': id }).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getBusiness(data) {
    const params = encodeGetParams(data);
    return sendLoggedGET(
        "get_business_list?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getBusinessByAccount(account) {
    const params = encodeGetParams({
        account: account,
    });
    return sendLoggedGET(
        "get_business_by_account?" + params
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function editBusiness(data) {
    return sendLoggedPOST("edit_business", data).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function deleteBusiness(data) {
    return sendLoggedPOST("delete_business", data).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function createQuoteStrategy(data) {
    return sendLoggedPOST("create_quote_strategy", data).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function removeQuoteStrategy(data) {
    return sendLoggedPOST("remove_quote_strategy", data).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function createEditRiderRideService(data) {
    return sendLoggedPOST("create_edit_rider_ride_service", data)
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}


export function setCommunitySettings(entries) {
    return sendLoggedPOST("set_community_settings", { entries })
        .then(function (response) {
            return response.data;
        }).catch(error => {
            console.error(error);
            return error;
        });
}

export function saveDriverNote(data) {
    return sendLoggedPOST("save_driver_note", data).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function deleteDriverNote(data) {
    return sendLoggedPOST("delete_driver_note", data).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getDriverNotes(driver) {
    return sendLoggedGET("get_driver_notes?driver=" + driver).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function uploadImage(data) {
    return sendLoggedPOST("upload_file", data).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function verifyRider(rider_account) {
    return sendLoggedPOST("verify_rider", { rider_account }).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getInvoicePublic(encrypted_uuid) {
    return sendLoggedGET("get_invoice_public?encrypted_uuid=" + encrypted_uuid).then(response => {
        return response.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function resetDriverStats(account_name) {
    return sendLoggedDELETE("drivers/" + account_name + "/statistics", API_VERSION_2).then(response => {
        return response.data
    }).catch(error => {
        console.error(error)
        return error
    })
}

// ANALYTCIS V2 APIs - START
export function getUsers(data, ignoreNotFound = false) {
    const params = encodeGetParams({
        ...data
    });

    return sendLoggedGET(`users/?` + params, API_VERSION_2, ignoreNotFound).then(result => {

        return result && result.data || null;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getUsersGroups(ignoreNotFound = false) {

    return sendLoggedGET(
        `analytics/groups/`, API_VERSION_2, ignoreNotFound
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getAssignedUserGroups(email, ignoreNotFound = false) {

    return sendLoggedGET(
        `users/groups/${email}/assigned/`, API_VERSION_2, ignoreNotFound
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function addUser(data) {
    return sendLoggedPOST(`users/`, data, API_VERSION_2).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
        return null;
    });
}

export function updateUser(data) {
    return sendLoggedPUTAsync(`users/`, data, API_VERSION_2).then(response => {
        return response;
    }).catch(error => {
        console.log(error)
        return null;
    });
}

export function IsFranchiseAdmin(ignoreNotFound = false) {

    return sendLoggedGET(
        'users/isFranchiseAdmin/', API_VERSION_2, ignoreNotFound
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getUserGroups(ignoreNotFound = false) {

    return sendLoggedGET(
        'users/groups/', API_VERSION_2, ignoreNotFound
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getGroupDashboards(group, ignoreNotFound = false) {
    return sendLoggedGET(
        `analytics/report/${group}/`, API_VERSION_2, ignoreNotFound
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getReport(dashboardId) {
    const url = apiAddress + API_VERSION_2 + `analytics/report/${dashboardId}/download/`;
    return axios.get(url, {
        responseType: 'blob',
        headers: {
            'Authorization': storeState.userData.authToken
        }
    }).then(result => {
        return result.data;
    }).catch(error => {
        if (error.response.status === 401) {
        } else if (error.response.status === 404) {
            return;
        } else {
            throw error;
        }
    });
}

export function getExchangeRates(rate = "") {
    const route = "exchangerates/" + rate
    return sendLoggedGET(
        route, API_VERSION_2
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getDeliveryProof(tracking_id) {
    return sendLoggedGET(`rides/${tracking_id}/delivery-proof`, API_VERSION_2, true, { responseType: "blob" });
}

export function getRideRefunds(ride_request_id) {
    const route = "invoices/ride_refund/"
    return sendLoggedGET(
        route + ride_request_id, API_VERSION_2
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function sendNewRefund(refund) {
    const route = "invoices/ride_refund/"
    return sendLoggedPOST(route + refund.ride_request_id, refund, API_VERSION_2).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

export function sendModifyRefund(refund) {
    const route = "invoices/ride_refund/"
    return sendLoggedPUTAsync(route + refund.ride_request_id, refund, API_VERSION_2).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

export function sendDeleteRefund(refund_id) {
    const route = "invoices/ride_refund/"
    return sendLoggedDELETE(route + refund_id, API_VERSION_2).then(function (response) {
        return response.data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
}

export function driverCashout(driverAccount, amount) {
    const route = `cashout/${driverAccount}`
    return sendLoggedPOST(
        route, {
        amount: amount
    }
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function mergeRides(mergeInstructions, validateOnly) {
    const route = "tasks/merge"
    return sendLoggedPOST(
        route, {
        merge_instructions: mergeInstructions,
        validate_only: validateOnly
    }, API_VERSION_2
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function verifyTask(taskId) {
    const route = `tasks/${taskId}`
    return sendLoggedGET(
        route, API_VERSION_2
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getTasks() {
    const route = `tasks/`
    return sendLoggedGET(
        route, API_VERSION_2
    ).then(result => {
        return result.data.tasks;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

//TODO: Remove
export function forceManualMerge(originTrackingId, destinationTrackingId) {
    const route = "force_manual_merge"
    return sendLoggedPOST(
        route, {
        originTrackingId,
        destinationTrackingId
    }
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function getRideServices() {
    const route = `services/?is_active=true&community=${process.env.REACT_APP_COMMUNITY_ACCOUNT}`
    return sendLoggedGET(
        route, API_VERSION_2
    ).then(result => {
        return result.data.available_services;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function saveRideService(rideServiceJson) {
    const route = `services/`
    return sendLoggedPOST(
        route, rideServiceJson, API_VERSION_2
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}

export function unStickForceMerge() {
    const route = `fix_stuck_merges`
    return sendLoggedPOST(
        route, {}, API_VERSION_1
    ).then(result => {
        return result.data;
    }).catch(error => {
        console.error(error);
        return error;
    });
}