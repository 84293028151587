import React, { useState } from "react"
import { Trans } from "react-i18next"
import {Table, Button } from 'react-bootstrap'

import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment"
import { utcStringToLocalString, getDifferenceInSeconds,isoToDateTime, convertSecondsToHoursString} from "../../helpers/dateHelper"
import './StepperSubTable.css'
import { completeStep, selectStep } from "../../helpers/apiHelper";



const CompleteStepButton = ({row, index}) => {
    const [alert, setAlert] = useState(null);

    if (row.is_step_done) return null;

    const handleCompleteStep = () => {
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Compléter l'étape"
                onConfirm={async () => {
                    await completeStep(row.route_id, row.step_id);
                    window.location.reload();
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                showCancel
            >
                <p>
                Compléter l'étape
                </p>
            </SweetAlert>
        )
    }

    return (<div>
         <Button
            onClick={handleCompleteStep}
            bsSize="xsmall"
            bsStyle="success"
            style={{ margin: "0px" }}
        >
            <Trans>Complete</Trans>
        </Button>
        {alert}
    </div>)
}

const SelectStepButton = ({row, index }) => {
    const [alert, setAlert] = useState(null);

    if (row.order_index == index) return null;
    if (row.ride_status >= 8) return null;
    if (row.step_type != 'GO_TO_DESTINATION') return null;

    const handleSelectStep = () => {
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Select step"
                onConfirm={async () => {
                    await selectStep(row.route_id, row.step_id);
                    window.location.reload();
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="success"
                showCancel
            >
                <p>
                Select step
                </p>
            </SweetAlert>
        )
    }

    return (<div>

        <Button
            onClick={handleSelectStep}
            bsSize="xsmall"
            bsStyle="warning"
            style={{ margin: "0px" }}
        >
            <Trans>Select</Trans>
        </Button>
        {alert}
    </div>)
}


const StepperSubTable=(props) =>{
    const rideData = props.data.original.ride;

    const getClassname = (row) => {
        let className = "";
        if(row.is_step_done) className += " step-done";
        if(row.order_index == rideData.current_stop_index) className += " current-step";
        return className;
    }
   

    function getRows() {
        const originalData = props.data.original
        const steps = originalData.ride.driver_route_ordered_steps;
        
        return (
            <>
                {steps.map(row => {
                    console.log(row);
                    return (
                        <tr className={getClassname(row)}>
                            <td style={{width:"10%"}} className="step-type">{<Trans>{row.step_type}</Trans>}</td>
                            <td style={{width:"10%"}}>{utcStringToLocalString(row.started_datetime)}</td>
                            <td style={{width:"10%"}}>{utcStringToLocalString(row.done_datetime)}</td>
                            <td style={{width:"5%"}}>{convertSecondsToHoursString(row.estimated_time_taken_seconds)}</td>
                            <td style={{width:"5%"}}>{convertSecondsToHoursString(row.real_time_taken_seconds)}</td>
                            <td>{row.from_address}</td>
                            <td>{row.to_address}</td>
                            <td>
                                <div style={{ display:'flex', gap: 5 }}>
                                    <CompleteStepButton row={row} index={rideData.current_stop_index}/>
                                    <SelectStepButton row={row} index={rideData.current_stop_index}/>
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    }



    return (
        <div>
            <Table striped size="sm">
            <thead>
                <tr>
                    <th><Trans>STEP</Trans></th>
                    <th><Trans>STARTED_DATETIME</Trans></th>
                    <th><Trans>DONE_DATETIME</Trans></th>
                    <th><Trans>MERGED_EST_MIN</Trans></th>
                    <th><Trans>TIME_TAKEN</Trans></th>
                    <th><Trans>FROM_ACCOUNT</Trans></th>
                    <th><Trans>TO_ACCOUNT</Trans></th>
                    <th><Trans>ACTIONS</Trans></th>
                </tr>
            </thead>
            <tbody className="table-api-subtable-container">
            {getRows()}
            </tbody>
            </Table>
        </div>
    )
}

export default StepperSubTable