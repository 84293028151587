import React, { useState, useEffect, useCallback, useRef, createContext } from 'react';
import { Trans } from "react-i18next";
import i18n from 'i18next';
import AnaTabs from '../../../components/AnaTabs/AnaTabs';
import ProgressRing from '../../../components/ProgressRing/ProgressRing';
import SubForm from "./SubForm/SubForm";
import {
    getDriverFormTemplate,
    getRideServiceInfo,
    getCommunitySettings,
    getNewDriverSubmission,
    saveDriverSubmission,
    migrateSubmission,
    sendSubmissionEmailReminder,
} from "../../../helpers/apiHelper";
import { useHistory } from 'react-router-dom';
import { formFieldsTemplate } from './template/formFieldsTemplate';
import LoadingIcon from '../../Components/LoadingIcon';
import { Button } from "react-bootstrap";
import backArrow from '../../../assets/img/back_arrow.svg';
import COMM_CONSTANTS from '../../Community/CommunityConstants';
import submissionStatusNames from '../../../const/SubmissionStatusNames';
import {
    getConfirmationAlert,
    getErrorAlert,
    getLoadingAlert,
    getSuccessAlert,
} from "../../../helpers/alertHelper";
import { useParams } from "react-router-dom";
import "./EditSubmission.css";
import DocumentPopUp from './DocumentPopUp/DocumentPopUp';
import { on } from 'events';

// TODO Error handling when fetching -> set timeout or something to avoid infinite loading and display errors. (W)
// TODO Enhance validation error message. Still got to find a solution cause it's not simple to generate a string from a regex in js. (W)

// OLD returned_ui_status
// const STATUS_OPEN = 1;
// const STATUS_VALIDATED = 2;
// const STATUS_ARCHIVED = 3;
// const STATUS_COMPLETED = 4;

// new user_status
const STATUS_OPEN = 5;
const STATUS_VALIDATED = 6;
const STATUS_ARCHIVED = 3;
const STATUS_COMPLETED = 7;

export const CommunitySettingsContext = createContext();
export const PhoneParamContext = createContext();

function EditSubmission(props) {
    /* useStates */
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState("");
    const [progress, setProgress] = useState(0);
    const [formFields, setFormFields] = useState({});
    const [lockAllFields, setLockAllFields] = useState(false);
    const [allVehiclesScopes, setAllVehiclesScopes] = useState({});
    const [driverActiveScopes, setDriverActiveScopes] = useState({ allowedRideServices: [], selectedVehicleTypes: [] });
    const [communitySettings, setCommunitySettings] = useState({});
    const [numberOfVehicles, setNumberOfVehicles] = useState(0);
    const [confirmationAlert, setConfirmationAlert] = useState();
    const vehicleTabAccordionState = useRef({});
    const { phone: phoneParam } = useParams();
    const [fetchedSubmission, setFetchedSubmission] = useState({});
    const [currentStatusId, setCurrentStatusId] = useState(STATUS_OPEN); // Set to ongoing by default
    const [showModal, setShowModal] = useState(false);
    const [imgURL, setImgURL] = useState("");
    const [imgName, setImgName] = useState("");

    /* useEffects */
    useEffect(() => {
        if (isLoading) {
            const fetchCommunityCustomFields = async () => {
                await getDriverFormTemplate("driver_signup_form")
                    .then(result => {
                        let parsedResult;
                        try {
                            parsedResult = JSON.parse(result);
                        } catch (error) {
                            parsedResult = [];
                        }
                        const driverCustomData = [];
                        const vehicleCustomData = [];

                        parsedResult.forEach(field => {
                            if (field.entity_scope === "driver") {
                                driverCustomData.push(field);
                            } else if (field.entity_scope === "vehicle") {
                                vehicleCustomData.push(field);
                            }
                        });

                        // Add custom fields to formFieldsTemplate
                        formFieldsTemplate.driver["custom"] = driverCustomData;
                        formFieldsTemplate.vehicle["custom"] = vehicleCustomData;
                    });
            };

            const fetchRideServices = async () => {
                await getRideServiceInfo().then(result => {
                    // Find allowed_ride_services in the vehicle template
                    // Note: getRideServiceInfo() fetch an object containing available rides services and vehicle types from actual vehicles in the DB. This will eventually change.
                    formFieldsTemplate.vehicle.basic.forEach(basicField => {
                        if (basicField.name === "allowed_ride_services") {
                            basicField.options = result.available_services;
                        }
                        if (basicField.name === "vehicle_type_id") {
                            basicField.options = result.available_vehicles;
                        }
                    });
                });
            }

            const fetchCommunitySettings = async () => {
                await getCommunitySettings(COMM_CONSTANTS.VEHICLE_RIDE_SERVICES).then(result => {
                    setCommunitySettings(result.data.value);
                });
            }

            const fetchSubmissionData = async () => {
                // If there is a submission to fetch, the data is converted to fit formFields.
                if (phoneParam) {
                    const extractObject = (fieldsObject) => {
                        const builtObject = {};
                        for (const attribute in fieldsObject) {
                            if (attribute === "custom_fields") {
                                fieldsObject['custom_fields'].forEach(field => (
                                    builtObject[field.field_name] = field.field_value
                                ));
                            } else {
                                builtObject[attribute] = fieldsObject[attribute];
                            }
                        }
                        return builtObject;
                    }

                    await getNewDriverSubmission(phoneParam, true).then(submissionData => {
                        
                        setCurrentStatusId(submissionData['status_id']);
                        const vehicles = submissionData['driver_vehicles'];
                        // ['progress', 'status_id', 'is_finished', 'driver_vehicles'].forEach(
                        //     attribute => delete submissionData[attribute]
                        // );
                        const driver = { ...submissionData };
                        const formattedFetchedSubmission = {};
                        formattedFetchedSubmission['driver'] = extractObject(driver);
                        formattedFetchedSubmission[`vehicles`] = [];
                        vehicles.forEach(vehicle => {
                            const vehicleObject = extractObject(vehicle);
                            formattedFetchedSubmission[`vehicles`].push(vehicleObject);
                        });
                        setProgress(submissionData.progress);
                        setFetchedSubmission(formattedFetchedSubmission);
                    })/*.catch(() => {
                        props.history.replace(`/drivers/submissions/new`);
                    });*/
                }
            }

            Promise.all([fetchCommunityCustomFields(), fetchRideServices(), fetchCommunitySettings(), fetchSubmissionData()]).then(() => {
            }).catch(error => {
                setLoadingError(error.message);
            }).finally(() => {
                setIsLoading(false);
            });

        } else {
            setFormFields(() => {
                const driverTemplate = formFieldsTemplate["driver"];
                const importedFieldsArray = [];
                for (const key in driverTemplate) {
                    driverTemplate[key].forEach(field => {
                        let value = (field.type === "checkbox") ? [] : "";
                        let isValidated = false;
                        if (fetchedSubmission.hasOwnProperty('driver')
                            && fetchedSubmission['driver'].hasOwnProperty(field.name)
                            && fetchedSubmission['driver'][field.name] !== null) {
                            value = fetchedSubmission['driver'][field.name];
                            isValidated = true;
                        }
                        importedFieldsArray.push({
                            ...field,
                            info_type: key,
                            id: generateId(),
                            value,
                            is_validated: isValidated,
                            show_error: false,
                            is_scoped: field.service_scope.length === 0 && field.vehicle_type_scope.length === 0,
                            translation: getTranslationKey(field["name"]),
                        });
                    });
                }
                return { driver: importedFieldsArray };
            });
            if (fetchedSubmission.hasOwnProperty('vehicles') && fetchedSubmission['vehicles'].length > 0) {
                fetchedSubmission['vehicles'].forEach(vehicleData => addVehicle(vehicleData));
            } else {
                addVehicle();
            }
        }
    }, [isLoading]);

    useEffect(() => {
        // Lock all fields if status is not set to 'open'.
        if (currentStatusId !== STATUS_OPEN) {
            setLockAllFields(true);
        } else {
            setLockAllFields(false)
        }
    }, [currentStatusId])

    useEffect(() => {
        let newAllowedRideServicesArray = [];
        let newSelectedVehicleTypesArray = [];
        Object.keys(allVehiclesScopes).forEach(formId => {
            const vehicleFormScopes = allVehiclesScopes[formId];
            console.log('vehicleFormScopes', vehicleFormScopes);
            console.log('formId'    , formId);
            newAllowedRideServicesArray = newAllowedRideServicesArray.concat(vehicleFormScopes["allowedRideServices"].filter(function (item) {
                return newAllowedRideServicesArray.indexOf(item) === -1;
            }));
            if (newSelectedVehicleTypesArray.indexOf(vehicleFormScopes["selectedVehicleType"]) === -1) {
                newSelectedVehicleTypesArray.push(vehicleFormScopes["selectedVehicleType"]);
            }
        })
        console.log('newAllowedRideServicesArray', newAllowedRideServicesArray);
        console.log('newSelectedVehicleTypesArray', newSelectedVehicleTypesArray);
        setDriverActiveScopes({
            allowedRideServices: newAllowedRideServicesArray,
            selectedVehicleTypes: newSelectedVehicleTypesArray
        });
    }, [allVehiclesScopes]);

    useEffect(() => {
        // "Activate" hidden fields that are targeted with new scopes
        const forms = { ...formFields };
        for (const formId in forms) {
            forms[formId].forEach(field => {
                const formServiceScope = (formId === "driver") ? driverActiveScopes.allowedRideServices : allVehiclesScopes[formId].allowedRideServices;
                const formVehicleScope = (formId === "driver") ? driverActiveScopes.selectedVehicleTypes : allVehiclesScopes[formId].selectedVehicleType;
                const isServiceScoped = field.service_scope.length === 0 || field.service_scope.some(service => formServiceScope.includes(service));
                let isVehicleTypeScoped = true;
                if (formId === "driver") {
                    isVehicleTypeScoped = field.vehicle_type_scope.length === 0 || field.vehicle_type_scope.some(vehicleType => formVehicleScope.includes(vehicleType));
                } else {
                    isVehicleTypeScoped = field.vehicle_type_scope.length === 0 || field.vehicle_type_scope.some(vehicleType => formVehicleScope === vehicleType);
                }
                field.is_scoped = isServiceScoped && isVehicleTypeScoped;
            });
        }
        setFormFields(forms);

    }, [driverActiveScopes])

    useEffect(() => {
        calculateProgress();
    }, [numberOfVehicles, driverActiveScopes]);

    /* Functions */
    const getTranslationKey = (fieldName) => {
        return `NEW_SUBMISSION_${fieldName.toUpperCase()}`; // Add prefix to translation and capitalize
    }

    const handleSubFormChange = (formId, updatedFormFieldsArray) => {
        setFormFields(currentValues => {
            return Object.assign({}, currentValues, { [formId]: updatedFormFieldsArray })
        });
    }

    const handleSubFormBlur = () => {
        calculateProgress();
    }

    const toggleShowDocument = (URL, fileName) => {

        const fileExt = fileName.split(".")[1];
        console.log(`file ext: ${fileExt}`);

        if(fileExt==="pdf" || fileExt==="doc") {
            window.open(URL, "_blank");
        } else {
            setImgURL(URL);
            setImgName(fileName);
            setShowModal(!showModal);
        }
    }

    const calculateProgress = () => {
        return;
        let requiredFieldsCount = 0;
        let validatedFieldsCount = 0;
        for (const form in formFields) {
            formFields[form].forEach(field => {
                if (field.required && field.is_scoped) {
                    requiredFieldsCount++;
                    if (field.is_validated) {
                        validatedFieldsCount++;
                    }
                }
            });
        }
        setProgress(Math.round((validatedFieldsCount / requiredFieldsCount) * 100) || 0);
    }

    const handleSubFormScopeChange = (formId, scopeObject) => {
        setAllVehiclesScopes(currentValues => {
            const formObject = { ...currentValues[formId], ...scopeObject }
            return { ...currentValues, [formId]: formObject };
        });
    }

    const addVehicle = (vehicleData = null) => {
        const vehicleFormId = vehicleData ? `vehicle-${vehicleData.vehicle_id}` : `new-vehicle-${generateId()}`;
        setFormFields(currentValues => {
            const vehicleTemplate = formFieldsTemplate["vehicle"];
            const importedFieldsArray = [];
            for (const key in vehicleTemplate) {
                vehicleTemplate[key].forEach(field => {
                    let value = (field.type === "checkbox") ? [] : "";
                    let isValidated = false;
                    if (vehicleData != null && vehicleData.hasOwnProperty(field.name) && vehicleData[field.name] !== null) {
                        value = vehicleData[field.name];
                        isValidated = true;
                    }
                    importedFieldsArray.push({
                        ...field,
                        info_type: key,
                        id: generateId(),
                        value,
                        is_validated: isValidated,
                        show_error: false,
                        is_scoped: field.service_scope.length === 0 && field.vehicle_type_scope.length === 0,
                        translation: getTranslationKey(field["name"]),
                    });
                });
            }
            return Object.assign({}, { ...currentValues }, { [vehicleFormId]: importedFieldsArray });
        });

        setAllVehiclesScopes(currentValues => {
            let allowedRideServices = [];
            let selectedVehicleType = null;
            if (vehicleData != null) {
                console.log('vehicleData in setAllVehiclesScopes', vehicleData)
                if (Array.isArray(vehicleData['allowed_ride_services'])) {
                    allowedRideServices = vehicleData['allowed_ride_services'];
                }
                if (typeof vehicleData['vehicle_type_id'] === "number") {
                    selectedVehicleType = vehicleData['vehicle_type_id'];
                }
            }
            return Object.assign({ ...currentValues }, { [vehicleFormId]: { allowedRideServices, selectedVehicleType } });
        })

        setNumberOfVehicles(numberOfVehicles + 1);

        // By default, each vehicle subform is expanded
        vehicleTabAccordionState.current[vehicleFormId] = true;
    };

    const removeVehicle = (formId) => {
        const updatedFormFields = { ...formFields };
        delete updatedFormFields[formId];
        setFormFields(updatedFormFields);
        setNumberOfVehicles(numberOfVehicles - 1);
    };

    const expandVehicleBody = useCallback((arrowButton, formId) => {
        /**
         * Note: Trying to keep track of if the subform is expanded or not when re-redering is hard with the actual code. 
         * Here would be a good starting point to refactor the code eventually.
         */
        vehicleTabAccordionState.current[formId] = !vehicleTabAccordionState.current[formId];
        const closestHead = arrowButton.closest("div.head");
        const body = closestHead.nextSibling;
        arrowButton.classList.toggle("active");
        if (body.style.maxHeight === "" || body.style.maxHeight !== "0px") {
            body.style.maxHeight = 0;
            body.style.margin = "0 30px";
            body.style.overflow = "hidden";
        } else {
            body.style.maxHeight = body.scrollHeight + "px";
            body.style.margin = "30px";
            body.style.overflow = "visible";
        }
    }, [vehicleTabAccordionState.current]);

    const sendReminderAction = () => {
        const onConfirm = () => sendReminderRequest();
        const confirmationBody = <Trans>NEW_SUBMISSION_SEND_REMINDER_CONFIRMATION</Trans>;
        setConfirmationAlert(getConfirmationAlert(onConfirm, closeConfirmationAlert, "Confirmation", confirmationBody));
    }

    const sendReminderRequest = async () => {
        // Little reminder here that we use the phone number as the ID to find the account in the database
        setConfirmationAlert(getLoadingAlert());
        const successTitle = <Trans>SUCCESS</Trans>;
        const successBody = <p><Trans>SEND_REMINDER_ALERT_SUCCESS_TEXT</Trans></p>;
        const errorTitle = <Trans>ERROR_ALERT</Trans>;
        const errorBody = <p><Trans>SEND_REMINDER_ALERT_ERROR</Trans></p>;

        await sendSubmissionEmailReminder(phoneParam).then(() => {
            setConfirmationAlert(getSuccessAlert(closeConfirmationAlert, successTitle, successBody));
        }).catch(() => {
            setConfirmationAlert(getErrorAlert(closeConfirmationAlert, errorTitle, errorBody));
        });
    }

    const saveCurrentSubmission = async (toArchive = false) => {
        setConfirmationAlert(
            getLoadingAlert()
        );

        let jsonEndpointObject = buildEndpointObject();

        jsonEndpointObject = {
            ...jsonEndpointObject,
            progress,
            status_id: toArchive ? STATUS_ARCHIVED : STATUS_OPEN,
        };

        await saveDriverSubmission(jsonEndpointObject).then(() => {
            closeConfirmationAlert();
            setCurrentStatusId(jsonEndpointObject['status_id']);
        }).catch(error => {
            generateErrorMessage(error, "NEW_SUBMISSION_ERROR_UPDATE_PROMPT");
        })
    }

    const generateErrorMessage = (error, errorTranslationKey) => {
        // Receives either an object containing the names of erroneous fields as keys from the server or a list of field names.
        let errorMessage;
        let isErrorDeserializable = false;
        let errorsList = [];
        if (error instanceof Error) {
            const errorsObject = error.response.data.errors;
            if (typeof errorsObject === "object" && Object.keys(errorsObject).length > 0) {
                isErrorDeserializable = true;
                errorsList = Object.keys(errorsObject);
            }
        } else if (Array.isArray(error)) {
            isErrorDeserializable = true;
            errorsList = error;
        }

        if (isErrorDeserializable) {
            errorMessage = (
                <>
                    <Trans>{errorTranslationKey}</Trans>
                    &ensp;
                    <Trans>NEW_SUBMISSION_ERROR_VERIFY_FIELDS</Trans>
                    <br/>
                    <br/>
                    {errorsList.map((errorKey, index) => {
                        const spacing = (errorsList.length === (index + 1)) ? "." : "; ";
                        return <span key={index}><b>{i18n.t(getTranslationKey(errorKey))}</b>{spacing}</span>
                    })}
                </>)
        } else {
            errorMessage = <><Trans>{errorTranslationKey}</Trans> <Trans>NEW_SUBMISSION_ERROR_CONTACT_DEV_TEAM</Trans></>
        }

        setConfirmationAlert(
            getErrorAlert(closeConfirmationAlert, <Trans>ERROR</Trans>, errorMessage)
        );
    }

    const buildEndpointObject = useCallback(() => {
        const jsonEndpointObject = { phone: phoneParam, driver_vehicles: [] };
        for (const formKey in formFields) {
            const form = formFields[formKey];
            const tempFormObject = { custom_fields: [] };
            form.forEach(field => {
                if (field.value != null && typeof field.value !== "undefined" && field.value !== "" && field.is_scoped) {
                    if (field.info_type === "basic") {
                        if (field.name === 'allowed_ride_services') {
                            // Not supposed to cast as a string in the first place, but it is to fit backend logic. This could be enhanced enventually.
                            tempFormObject[field.name] = `[${field.value}]`;
                        } else if (field.name === "reference_id") {
                            tempFormObject[field.name] = parseInt(field.value);
                        } else {
                            tempFormObject[field.name] = field.value;
                        }
                    }
                    else if (field.info_type === "custom") {
                        const customFieldObject = {
                            field_name: field.name,
                            field_value: String(field.value)
                        }
                        tempFormObject['custom_fields'].push(customFieldObject);
                    }
                }
                if (formKey != 'driver'){
                    if (formKey.includes('new-vehicle')) {
                        // new vehicle - dont add vehicle_id to form object.
                    } else {
                        let vehicle_id = formKey.replace("vehicle-", "")
                        tempFormObject['vehicle_id'] = parseInt(vehicle_id);
                    }
                }
                    
            });
            if (formKey === "driver") {
                Object.assign(jsonEndpointObject, tempFormObject);
            } else if (formKey.includes('vehicle')) {
                jsonEndpointObject['driver_vehicles'].push(tempFormObject);
            }
        }

        return jsonEndpointObject;
    }, [formFields])

    const validateAll = useCallback(async (saveOnly = false) => {
        // Validate all fields and calculate progress at the same time.
        const updatedFormFields = {};
        let allValuesValid = true;
        const invalidFieldsList = [];

        for (const form in formFields) {
            const newformFieldsArray = [];
            formFields[form].forEach(field => {
                const { is_scoped, required, validation_regex, value } = field;
                field["show_error"] = false;
                field["is_validated"] = false;
                if (is_scoped) {
                    // Note: Conditions change depending on the saveOnly option.
                    const fieldRegex = new RegExp(validation_regex);
                    const canBeEmpty = saveOnly? true : !required;
                    const regexTestPassed = fieldRegex.test(value);
                    const isValueValid = regexTestPassed || (value === "" && canBeEmpty);
                    field["show_error"] = !isValueValid;
                    field["is_validated"] = isValueValid;
                    if (!isValueValid) {
                        allValuesValid = false;
                        invalidFieldsList.push(field.name);
                    }
                }
                newformFieldsArray.push(field);
            });
            updatedFormFields[form] = newformFieldsArray;
        }
        setFormFields(updatedFormFields);

        if (allValuesValid) {
            await saveCurrentSubmission().then(async () => {
                const jsonEndpointObject = buildEndpointObject();
                if(!saveOnly) {
                    setConfirmationAlert(getLoadingAlert());
                    await migrateSubmission(jsonEndpointObject, { ValidateOnly: true }).then(async () => {
                        jsonEndpointObject['status_id'] = STATUS_VALIDATED;
    
                        closeConfirmationAlert();
                        setLockAllFields(true);
                        setCurrentStatusId(jsonEndpointObject['status_id']);
                    }).catch(error => {
                        generateErrorMessage(error, "NEW_SUBMISSION_ERROR_VALIDATE_ALL_PROMPT");
                    });
                } else {
                    setLockAllFields(true);
                    setLockAllFields(true); // apparently, once is not enough.
                    window.location.reload();
                }
            })
        } else {
            const errorTranslationKey = saveOnly? "NEW_SUBMISSION_ERROR_UPDATE_PROMPT" : "NEW_SUBMISSION_ERROR_MISSING_FIELDS_PROMPT";
            generateErrorMessage(invalidFieldsList, errorTranslationKey);
        }
    })

    const unlockAllFields = () => {
        setLockAllFields(false);
    }

    const handleCompleteButton = useCallback(() => {
        setConfirmationAlert(
            getConfirmationAlert(migrateSubmissionToDriver, closeConfirmationAlert, <Trans>ARE_YOU_SURE</Trans>, <Trans>NEW_SUBMISSION_CONFIRMATION_ALERT_TEXT</Trans>)
        );
    })

    const migrateSubmissionToDriver = useCallback(async () => {
        setConfirmationAlert(
            getLoadingAlert()
        );

        const jsonEndpointObject = buildEndpointObject();
        await migrateSubmission(jsonEndpointObject, { ValidateOnly: false }).then(async () => {
            jsonEndpointObject['status_id'] = STATUS_COMPLETED;
            await saveDriverSubmission(jsonEndpointObject).then(() => {
                setConfirmationAlert(
                    getSuccessAlert(closeConfirmationAlert, <Trans>SUCCESS</Trans>, <Trans>NEW_SUBMISSION_SUCCESS_ALERT_TEXT</Trans>)
                );
                setCurrentStatusId(STATUS_COMPLETED);
                window.location.reload();
            }).catch(() => {
                setConfirmationAlert(
                    getErrorAlert(closeConfirmationAlert, <Trans>ERROR</Trans>, <Trans>NEW_SUBMISSION_ERROR_STATUS_PROMPT</Trans>)
                )
            })
        }).catch(() => {
            setConfirmationAlert(
                getErrorAlert(closeConfirmationAlert, <Trans>ERROR</Trans>, <Trans>NEW_SUBMISSION_ERROR_ALERT_TEXT</Trans>)
            );
        });
    });

    const closeConfirmationAlert = useCallback(() => {
        setConfirmationAlert();
    });

    const onClickBalance = useCallback(() => {
        history.push(`/rider?name=${phoneParam}`);
      }, [history, phoneParam]);

    

    /* Variables */
    let driverForm, vehicleForm;
    if (Object.keys(formFields).length > 0) {
        driverForm = (
            <div className={`subform-driver-card`}>
                <SubForm
                    formId={"driver"}
                    type={"driver"}
                    fieldsArray={formFields.driver}
                    lockAllFields={lockAllFields}
                    onChange={handleSubFormChange}
                    onBlur={handleSubFormBlur}
                    onClick={toggleShowDocument}
                />
            </div>
        );

        vehicleForm = (
            <>
                {Object.keys(formFields).map((formId, index) => {
                    if (formId.includes('vehicle')) {
                        const isAccordionExpanded = vehicleTabAccordionState.current[formId] && "active"
                        return (
                            <div className={`subform-vehicle-card`} key={index}>
                                <div className="head">
                                    <h2><Trans>VEHICLE</Trans> {index}</h2>
                                    {lockAllFields || <Button
                                        onClick={() => removeVehicle(formId)}
                                        bsSize="small"
                                        bsStyle="danger"
                                    >
                                        <Trans>NEW_SUBMISSION_REMOVE_VEHICLE</Trans>
                                    </Button>}
                                    <img
                                        src={backArrow}
                                        alt="arrow button to expand and close a vehicle form"
                                        onClick={e => expandVehicleBody(e.target, formId)}
                                        className={`${isAccordionExpanded && "active"}`}
                                    />
                                </div>
                                <div className="body">
                                    <SubForm
                                        formId={formId}
                                        type={"vehicle"}
                                        fieldsArray={formFields[formId]}
                                        lockAllFields={lockAllFields}
                                        onChange={handleSubFormChange}
                                        onBlur={handleSubFormBlur}
                                        activeScopes={allVehiclesScopes[formId]}
                                        onScopeChange={handleSubFormScopeChange}
                                    />
                                </div>
                            </div>);
                    };
                })}
                { lockAllFields || <button
                    type="button"
                    onClick={() => addVehicle()}
                    className="add-button"
                >
                    +
                </button>}
            </>
        );
    }

    const tabs = [
        { id: 1, name: <Trans>NEW_SUBMISSION_DRIVER_TAB</Trans>, body: driverForm },
        { id: 2, name: <Trans>NEW_SUBMISSION_VEHICLE_TAB</Trans>, body: vehicleForm }
    ];

    const bodyToRender = () => {
        let body;
        const isArchived = currentStatusId === STATUS_ARCHIVED;
        if (isLoading) {
            body = <LoadingIcon />
        } else if (loadingError) {
            body = <p className="fetching-error-box">&#9888; {loadingError}</p>
        } else {
            body = (
                <form>
                    <Button bsStyle="info" style={{'width': '70%', height: 100}} onClick={onClickBalance}>
                        <Trans>SEE_BALANCE_TRANSACTIONS</Trans>
                    </Button>
                    {
                        <CommunitySettingsContext.Provider value={communitySettings}>
                            <PhoneParamContext.Provider value={phoneParam}>
                                <AnaTabs content={tabs} />
                            </PhoneParamContext.Provider>
                        </CommunitySettingsContext.Provider>
                    }

                    {isArchived || <div className="bottom-buttons-container">
                        {lockAllFields ?
                            <>
                                <Button
                                    onClick={unlockAllFields}
                                    bsStyle="primary"
                                >
                                    <Trans>NEW_SUBMISSION_EDIT_BUTTON</Trans>
                                </Button>
                                <Button
                                    onClick={handleCompleteButton}
                                    bsStyle="success"
                                >
                                    <Trans>NEW_SUBMISSION_COMPLETE_BUTTON</Trans>
                                </Button>
                            </>
                            :
                            <>
                                <Button
                                    onClick={() => validateAll(true)}
                                    bsStyle="primary"
                                >
                                    <Trans>NEW_SUBMISSION_SAVE_BUTTON</Trans>
                                </Button>
                                
                            </>
                        }
                    </div>}
                </form>
            )
        }
        return body;
    };

    const existingDriverInfo = ('driver' in fetchedSubmission) && fetchedSubmission.driver;
    const nameToDisplay = existingDriverInfo['first_name'] && existingDriverInfo['last_name'] ?
        `${existingDriverInfo['first_name']} ${existingDriverInfo['last_name']}` : <Trans>NEW_SUBMISSION_HEADER_TITLE</Trans>;
    const isArchived = currentStatusId === STATUS_ARCHIVED;

    return (
        <div className="edit-driver-submission-container main-content" >
            <>
                {confirmationAlert}
                <section className="header">
                    <div className="progress-and-status">
                        {!existingDriverInfo.blacklist_timestamp && <ProgressRing progress={progress} width='110' />}
                        
                    </div>
                    <div className='left'>
                        {existingDriverInfo.blacklist_timestamp && (<>
                            <h2 style={{ fontWeight: 900, color: 'red' }}>BLACKLISTED</h2>
                        </>)}
                        <h1>
                            {nameToDisplay}
                        </h1>
                        <h3>
                            <Trans>{submissionStatusNames[currentStatusId]}</Trans>
                        </h3>
                        <h2>{phoneParam}</h2>
                    </div>
                    <div className='right'>
                        {existingDriverInfo &&
                            <>
                                
                            </>
                        }
                    </div>
                </section>
                <section className="body">
                    {bodyToRender()}
                </section>
                <DocumentPopUp showModal={showModal} close={toggleShowDocument} imgURL={imgURL} imgName={imgName}/>
            </>
        </div>
    );
}

export default EditSubmission;

const generateId = () => {
    // Tiny ID generator
    return Math.random().toString(36).substr(2, 9);
};